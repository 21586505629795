import CenteredText from "./CenteredText"

export default function Home({ children }){

  return (
    <CenteredText>
      <div>
        <h1>triqueue.com</h1>
        <h6>a site by li jacobsyn</h6>
      </div>
    </CenteredText>
  );
};